

$master-mint-20: rgba(0, 123, 124, 0.2);
$master-mint-30: rgba(0, 123, 124, 0.3);
$master-mint-50: rgba(0, 123, 124, 0.5);


$sure-sage: #2D8C8C;
$bold-blueberry: #41B4D2;
$friendly-fig: #005A8C;
$reliable-rhubarb: #F07791;
$confident-corn: #FFEB78;
$master-mint-800: #005962;
$master-mint-700: #007B7C;

$master-mint: $master-mint-700;

$cool-coconut: #FFFFFF;
$cool-coconut-20: rgba(255, 255, 255, 0.2);

$perfect-pepper: #000000;
$perfect-pepper-10: rgba(0, 0, 0, 0.1);
$perfect-pepper-20: rgba(0, 0, 0, 0.2);
$perfect-pepper-30: rgba(0, 0, 0, 0.3);
$perfect-pepper-50: rgba(0, 0, 0, 0.5);

$input: #c8c8c8;
$input-20: rgba(200, 200, 200, 0.2);
$input-50: rgba(200, 200, 200, 0.5);

$branding-bg: #EFEFEF;
$success : #008556;
$warning : #FE6C30;
$error : #D81B2F;
$error-50: rgba(243, 125, 115, 0.5);

$info : #005A8C;
$info-50: rgba(0, 90, 140, 0.5);
$info-75: rgba(0, 90, 140, 0.75);

$success-100: #EEF7F4;
$warning-100: #FEECD4;
$error-100: #FFEAE4;
$info-100: #E9FFFD;

$black : #000000;
$header : #121317;
$body: #575665;
$subtext: #8B8B9A;

$highlight: #BFBDC8;
$highlight-50: rgba(191, 189, 200, 0.5);

$border: #E2E1E6;
$light: #EBEBF1;
$background: #F6F7FA;
$row-background: #FAFAFA;
$white: #FFFFFF;

$master-mint-900: #004150;
$master-mint-600: #149990;
$master-mint-500: $master-mint;
$master-mint-400: #58D2B5;
$master-mint-300: #7cE8C5;
$master-mint-200: #AAF7D8;
$master-mint-100: #D3FAE7;

$sure-sage-900: #164545;
$sure-sage-800: #1D5959;
$sure-sage-700: #236D6D;
$sure-sage-600: #2B8182;
$sure-sage-500: $sure-sage;
$sure-sage-400: #38AEAE;
$sure-sage-300: #4FC6C6;
$sure-sage-200: #84D7D7;
$sure-sage-100: #E5F5F4;

$bold-blueberry-900: #033258;
$bold-blueberry-800: #06456C;
$bold-blueberry-700: #0E628A;
$bold-blueberry-600: #4282a4;
$bold-blueberry-500: $bold-blueberry;
$bold-blueberry-400: #54CEDE;
$bold-blueberry-300: #76E8EE;
$bold-blueberry-200: #A4F9F6;
$bold-blueberry-100: #D0FBF8;

$friendly-fig-900: #00334F;
$friendly-fig-800: #003A59;
$friendly-fig-700: #00466C;
$friendly-fig-600: #004D76;
$friendly-fig-500: $friendly-fig;
$friendly-fig-400: #007CBF;
$friendly-fig-300: #009FF4;
$friendly-fig-200: #45BEFF;
$friendly-fig-100: #7AD0FF;

$reliable-rhubarb-900: #6C183B;
$reliable-rhubarb-800: #842745;
$reliable-rhubarb-700: #A93C57;
$reliable-rhubarb-600: #CF576C;
$reliable-rhubarb-500: $reliable-rhubarb;
$reliable-rhubarb-400: #F6B3BA;
$reliable-rhubarb-300: #F8C4C9;
$reliable-rhubarb-200: #FAD5D9;
$reliable-rhubarb-100: #FCE6E8;

$confident-corn-900: #877300;
$confident-corn-800: #A28900;
$confident-corn-700: #D8B700;
$confident-corn-600: #FFDB0F;
$confident-corn-500: $confident-corn;
$confident-corn-400: #FFEE93;
$confident-corn-300: #FFF3A8;
$confident-corn-200: #FFF8C4;
$confident-corn-100: #FFFBE0;

/* Semantic Colors */
$success-900: #093421;
$success-800: #0F3F24;
$success-700: #134F29;
$success-600: #1C5E2E;
$success-500: $success;
$success-400: #56A85C;
$success-300: #84D380;
$success-200: #B9EFB1;



$warning-900: #790D0F;
$warning-800: #921C16;
$warning-700: #B73118;
$warning-600: #DB4C22;
$warning-500: $warning;
$warning-400: #FF9B63;
$warning-300: #FEB781;
$warning-200: #FFD5AB;


$error-900: #660933;
$error-800: #7C0C36;
$error-700: #9B0C37;
$error-600: #B81333;
$error-500: $error;
$error-400: #E84F51;
$error-300: #F37D73;
$error-300-20: rgba(243, 125, 115, 0.2);
$error-200: #FBB2A3;

$info-900: #00193A;
$info-800: #002246;
$info-700: #002E58;
$info-600: #003D3B;
$info-500: $info;
$info-400: #1585B0;
$info-300: #3FB5D5;
$info-200: #7EDEF0;
