@import "./_worldline-brand.scss";

/* This file may only contain variables or SASS will duplicate the final css
 as many times as this files has been imported into other scss files */

/* Logo */
$logo-size: 82px;


/* Colors */
$color-primary: $sure-sage;
$color-primary-dimmed: $master-mint;

$color-taupe-0: $sure-sage;
$color-taupe-1: $highlight;
$color-taupe-2: $confident-corn-100;
$color-taupe-3: $sure-sage-100;
$color-taupe-4: $background;

$background-color-container: $background;
$background-color-form: $background;

$color-text-body: $body;
$color-text-header: $header;
$color-text-blue: $info-300;
$color-link: $info-300;
$color-label: $header;

$text-light: $perfect-pepper-50;

$color-green: $success-400;

$color-remove-text: $error-300;
$color-yellow: $confident-corn;

$color-grey-dark: $body;
$color-grey: $highlight;

$color-body: $body;
$color-subtext: $subtext;

$color-error: $error-300;
$color-error-dark: $error-400;
$color-error-background: $error-300-20;
$color-error-message: $error;
$color-error-message-background: $error-300-20;

$color-highlight: $highlight;

$color-info: $info;

$color-anchor: $sure-sage-800;

$color-info: $info;

$background-color-light: $light;

$bg-color-rhubarb-red: $reliable-rhubarb-600;
$bg-color-rhubarb: $reliable-rhubarb;


/* Buttons */
$button-disabled-background-color: $background;
$button-disabled-text-color: $error-100;

/* Borders */
$border-radius: 4px;
$border-radius-large: 8px;
$border-radius-small: 1px;
$border-form: 1px solid $border;
$border-form-dashed: 1px dashed $border;
$border-generic: $border;

/* Text Colors */
$h3-color: $subtext;

/* Panels */
$panel-label-color: $highlight;
$panel-border-color: $border;
$panel-background-color: $background;
$panel-alt-background-color: $row-background;
$panel-text-color: $body;

/* Radio buttons */
$radio-button-border-color: $highlight;
$radio-button-selected-border-color: $body;

/* Checkboxes */
$checkbox-border-color: $highlight;
$checkbox-selected-border-color: $body;
$radio-button-focus-border-color: $info-400;

/* Dropdowns */
$dropdown-text-color: $body;
$dropdown-background-color: $white;
$dropdown-border-color: $border;
$dropdown-background-color-hover: $master-mint-20;

/* Inputs */
$input-main-gray: $header;
$input-border-color: $border;
$input-border-color-dark: $subtext;
$input-border-color-green: $success-300;
$input-text-color: $body;
$input-placeholder-color: $subtext;
$input-glow-color: $bold-blueberry-200;
$input-bg-disabled: $master-mint-50;
$input-bg-disabled-text: $white;
$input-focus: $subtext;
$input-readonly: $highlight;
$input-bg: $row-background;

$input-font-size: 16px;
$input-font-size-large: 27px;
$input-height: 55px;
$input-label-color: $input-placeholder-color;

/* Rows */
$color-row-even: transparent;
$color-row-odd: $row-background;
$color-row-selected: $master-mint-100;
